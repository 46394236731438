a {cursor:pointer;}
strong {font-weight:bold;}
em {font-style:italic;}
sup, sub {position: relative;top: -0.4em;vertical-align: baseline;}
sub { top: 0.4em; }
tr {height:20px;}
span.highlight {background-color:#d6ecff}
section img {max-width:100%;height:auto;}  

/* collapse/expand large tables */
.table-wrapper {position:relative;overflow: hidden;margin:-30px 0 0 -30px;padding:30px 0 30px 30px;}
.table-wrapper table {width:880px;margin-bottom:0;}
.table-overlay {z-index: 1000001;position:absolute;top:29px;right:-1px;bottom:29px;width:40%;box-shadow: inset -18px 1px 48px -35px rgba(0,0,0,0.4);
background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 90%, rgba(255,255,255,1) 100%); /* FF3.6+ */
background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(255,255,255,0)), color-stop(90%,rgba(255,255,255,1)), color-stop(100%,rgba(255,255,255,1))); /* Chrome,Safari4+ */
background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 90%,rgba(255,255,255,1) 100%); /* Chrome10+,Safari5.1+ */
background: -o-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 90%,rgba(255,255,255,1) 100%); /* Opera 11.10+ */
background: -ms-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 90%,rgba(255,255,255,1) 100%); /* IE10+ */
background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 90%,rgba(255,255,255,1) 100%); /* W3C */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
}
.table-zoom {z-index: 1000002;position:absolute;right:-1px;width:40px;height:40px;background-color:#fff;border:1px solid #ddd;background:url(../img/zoom.png) center center no-repeat #fff;}
.table-close {display:none;position:absolute;top:10px;right:10px;width:35px;height:30px;border:1px solid #ddd;background:url(../img/close.png) center center no-repeat #fff;}
.table-wrapper.popup {overflow:visible;float: right;margin:0;padding:50px 20px 20px;background-color: #fff;box-shadow: 0 0 25px -5px;}
.popup .table-overlay, .popup .table-zoom {display:none;}
.popup .table-close {display:block;}

/* tooltips */
span.hint--bottom {line-height: 12px;border-bottom:1px dotted #000;}
span.hint--bottom:after {width:400px;line-height:16px;white-space:normal;}
.hint--leftside.hint--bottom:before, .hint--leftside.hint--bottom:after {left: auto;right:50%;margin-right: -100%;}
.hint--leftside.hint--bottom:after {margin-right:-20px;}
/*.right*/.hint--bottom:before, /*.right*/.hint--bottom:after {margin-left: -100%;}
/*.right*/.hint--bottom:after {margin-left:-20px;}

.noselect {-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;-o-user-select:none;user-select:none;}

/* ol list-styles */.list-style-decimal{list-style:decimal}.list-style-decimal-leading-zero{list-style:decimal-leading-zero}.list-style-lower-alpha{list-style:lower-alpha}.list-style-upper-alpha{list-style:upper-alpha}.list-style-lower-roman{list-style:lower-roman}.list-style-upper-roman{list-style:upper-roman}
/* ul list styles */.list-style-none{list-style:none}.list-style-square{list-style:square}.list-style-circle{list-style:circle}.list-style-disc{list-style:disc}
.marginbottom-none{margin-bottom:0px;}

.content-container ol:not(.footnotes) li {
    margin-bottom: 0px !important;
}
ol, ul, p{
  &+h4 {
    margin-top: 22px;
  }
}

.highlight-block.white * {
  color: rgb(15,35,140);
}

h1 {
  color: rgb(255,0,188);
  text-transform: uppercase;
}

h2 {
  text-transform: uppercase;
  font-size: 24px;
  line-height: 34px;
}

h3 {
  font-weight:500;
  font-size: 30px;
  line-height: 40px;
  margin-bottom: 3px
}

h4 {
  font-size:18px;
  line-height:26px;
  font-weight:700;
  color: rgb(15,35,140);
  margin-bottom: 3px;
}

h5 {
  font-size:15px;
  line-height:22px;
  margin-bottom: 3px;
}

h6 {
  font-weight:300;
}
